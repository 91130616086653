import React from "react";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, BarChart } from "recharts";
import EmptyTableContainer from "components/CustomComponents/EmptyTableContainer/EmptyTableContainer";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { AxisDomain, LayoutType, AxisInterval } from "recharts/types/util/types";
import { lineChartTooltipStyles } from "pages/common";

interface BarChartAbsolute {
    payload: Array<{}>;
    keys: Array<string>;
    fill: string;
    yLabel?: string;
    xLabel?: string;
    width?: number;
    height?: number;
    domain?: AxisDomain;
    layout?: LayoutType;
    legendFormatter?: Formatter;
    xAxisInterval?: AxisInterval;
    title?: string;
}

export const BarChartAbs = (props: BarChartAbsolute) => {
    const {
        payload,
        keys,
        fill,
        yLabel,
        xLabel,
        width = 650,
        height = 310,
        domain = [0, "auto"],
        layout = "horizontal",
        title,
    } = props;

    const customFormatter = (value) => {
        return `${value}`;
    };

    return payload && payload.length !== 0 ? (
        <div
            style={{
                border: "1px solid #ccc",
                padding: "20px",
                borderRadius: "8px",
            }}
        >
            {title && (
                <div
                    style={{
                        textAlign: "left",
                        backgroundColor: "#f0f0f0",
                        padding: "10px",
                        fontWeight: "normal",
                    }}
                >
                    <h3 style={{ margin: 0, fontWeight: "normal" }}>{title}</h3>
                </div>
            )}
            <BarChart
                width={width}
                height={height}
                data={payload}
                layout={layout}
                margin={{ top: 20, bottom: 40 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    type="category"
                    dataKey="name"
                    label={
                        xLabel
                            ? { value: xLabel, position: "insideBottom", dy: 20 }
                            : null
                    }
                />
                <YAxis
                    type="number"
                    domain={domain}
                    width={80}
                    label={
                        yLabel
                            ? {
                                  value: yLabel,
                                  angle: -90,
                                  dx: -30,
                                  position: "outisdeLeft",
                              }
                            : null
                    }
                />
                <Tooltip
                    labelStyle={lineChartTooltipStyles.labelStyle}
                    itemStyle={lineChartTooltipStyles.itemStyle}
                    formatter={customFormatter}
                    animationDuration={0}
                    animationEasing="linear"
                />
                {keys.map((key) => {
                    return (
                        <Bar
                            dataKey={key}
                            fill={fill}
                            barSize={50}
                            key={`bar-${key}`}
                        />
                    );
                })}
            </BarChart>
        </div>
    ) : (
        <EmptyTableContainer />
    );
};
