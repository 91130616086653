import {
    erfmDates,
    erfmTable,
    erfmAppChart,
    erfmCountryChart,
} from "queries/ERFM/erfmQueries";
import {
    upsellFunnelDates,
    upsellFunnelStages,
    upsellFunnelFilterChoices,
    engagementFunnelDates,
    engagementFunnelFilterChoices,
    engagementFunnelStages,
    engagementFunnelStandaloneMetrics,
} from "queries/ERFM/erfmFunnelQueries";

import { erfmPreLicenseTypeTable, erfmPreLicenseTypeDataDates } from "queries/ERFM/ERFMPreLicenseType";

export const erfmQueries = {
    erfmDates,
    erfmTable,
    erfmAppChart,
    erfmCountryChart,
    upsellFunnelDates,
    upsellFunnelStages,
    upsellFunnelFilterChoices,
    engagementFunnelDates,
    engagementFunnelFilterChoices,
    engagementFunnelStages,
    engagementFunnelStandaloneMetrics,
    erfmPreLicenseTypeTable,
    erfmPreLicenseTypeDataDates,
};
