export const erfmPreLicenseTypeDataDates = `
// ERFM Dates
database('ConsumerInsights').eRFMPreLaunchLicenseType
| extend Date = format_datetime(ProcessDate, "yyyy-MM-dd")
| distinct Date
| order by Date desc
    `;

export const erfmPreLicenseTypeTable = `
// ERFM PreLicenseType Table
database('ConsumerInsights').eRFMPreLaunchLicenseType
| where AppName == "\${application}" and ProcessDate == datetime(\${date}) and ClientCountry == "\${country}"
| extend Application = iff(AppName == 'All', 'WXP', AppName), PreLaunchLicenseType = iff(PreLicenseStateType !in ('RFM', 'Grace', 'Expired License / Subscripton'), 'Unknown', PreLicenseStateType)
| project Date = format_datetime(ProcessDate, "yyyy-MM-dd"), Application, PreLaunchLicenseType, ClientCount
| summarize sum(ClientCount) by Date, Application, PreLaunchLicenseType
    `;
